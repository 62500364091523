<template>
  <b-container fluid>
      <iq-card>
         <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_tpm.training_calendar') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
            <b-col lg="12" sm="12">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                  <b-row>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                            </template>
                            <v-select name="organization"
                              v-model="search.fiscal_year_id"
                              label="text"
                              :reduce="item => item.value"
                              :options= fiscalYearList
                              :placeholder="$t('globalTrans.select')"
                              :filter-by="myFilter"
                              :state="errors[0] ? false : (valid ? true : null)"
                            />
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1">
                      <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                        <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="org_id"
                  >
                  <template v-slot:label>
                    {{ $t('globalTrans.organization')}}
                  </template>
                  <v-select name="org_id"
                    v-model="search.org_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= orgList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
                        <!-- <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group> -->
                      </ValidationProvider>
                      </b-col>
                  </b-row>
                  <div class="row">
                    <b-col sm="9">
                    </b-col>
                    <b-col sm="3" style="text-align: right;">
                      <b-button size="sm" type="submit" variant="primary" class="mt-20">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                      </b-button>
                    </b-col>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-col>
              </b-row>
          </template>
      </iq-card>
      <b-row>
        <b-col md="12" v-if="showData">
          <b-overlay :show="loading">
            <b-row v-if='datas.length'>
              <b-col md="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('elearning_tpm.training_calendar') }}</h4>
                  </template>
                  <template v-slot:headerAction>
                      <b-button variant="primary btn_add_new" @click="pdfExport" class="mr-2">
                        <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                      </b-button>
                      <b-button variant="primary btn_add_new" @click="wordExport" class="mr-2">
                        <i class="fas fa-print"></i>{{  $t('globalTrans.word') }}
                      </b-button>
                  </template>
                  <template v-slot:body>
                    <b-overlay :show="loader">
                      <b-row mt-5>
                        <b-col md="12" class="table-responsive">
                          <div style="border: 2px solid;margin:10px;padding:10px">
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                  <template v-slot:projectNameSlot>
                                    {{ }}
                                  </template>
                                  <span v-if="search.org_id === 12">{{ $t('elearning_tpm.ac_training_calendar') }} {{ '(' }}{{ ($i18n.locale==='bn') ? searchHeaderData.fiscal_year_bn : searchHeaderData.fiscal_year_en }}{{ ')' }}</span>
                                  <span v-else>{{ $t('elearning_tpm.training_calendar') }} {{ '(' }}{{ ($i18n.locale==='bn') ? searchHeaderData.fiscal_year_bn : searchHeaderData.fiscal_year_en }}{{ ')' }}</span>
                                </list-report-head>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <div class="text-center">
                                </div>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length">
                                  <b-thead>
                                    <b-tr>
                                      <b-th style="width:1%">{{$t('globalTrans.sl_no')}}</b-th>
                                      <b-th style="width:1%">{{$t('elearning_config.training_title')}}</b-th>
                                      <b-th style="width:1%">{{ $i18n.locale === 'en' ? 'Days' : 'দিন' }}</b-th>
                                      <b-th style="width:1%">{{ $i18n.locale === 'en' ? 'No of Batch' : 'ব্যাচ সংখ্যা' }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Jul/' : 'জুলাই/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Aug/' : 'আগস্ট/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Sep/' : 'সেপ্টে/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Oct/' : 'অক্টো/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Nov/' : 'নভে/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Dec/' : 'ডিসে/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Jan/' : 'জানু/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Feb/' : 'ফেব্রু/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Mar/' : 'মার্চ/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Apr/' : 'এপ্রিল/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'May/' : 'মে/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Jun/' : 'জুন/' }}{{ fiscalYearEnd() }}</b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr v-for="(data, index) in datas" :key="index">
                                      <b-td class="text-center" style="font-size: 10px">
                                        {{ $n(index + 1) }}
                                      </b-td>
                                      <b-td style="font-size: 10px">
                                        {{ getTrainingTitle(data.training_title_id) }}
                                      </b-td>
                                      <b-td class="text-center" style="font-size: 10px">
                                        <!-- {{ $n(data.no_of_days) }} -->
                                        {{ $n( countDay(data.training_title_id)) }}
                                      </b-td>
                                      <b-td class="text-center" style="font-size: 10px">
                                        {{ $n( getBatch(data.training_title_id)) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 6) > 1 || calculateColspanYear(data.training_title_id, 6) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 6) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 6) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 6) }}
                                        {{ durationEnd(data.id, data.training_title_id, 6) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 6) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 7) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 7) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 7) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 7) }}
                                        {{ durationEnd(data.id, data.training_title_id, 7) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 7) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 8) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 8) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 8) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 8) }}
                                        {{ durationEnd(data.id, data.training_title_id, 8) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 8) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 9) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 9) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 9) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 9) }}
                                        {{ durationEnd(data.id, data.training_title_id, 9) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 9) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 10) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 10) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 10) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 10) }}
                                        {{ durationEnd(data.id, data.training_title_id, 10) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 10) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 11) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 11) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 11) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 11) }}
                                        {{ durationEnd(data.id, data.training_title_id, 11) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 11) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 0) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 0) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.id, data.training_title_id, 0) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 0) }}
                                        {{ durationEnd(data.id, data.training_title_id, 0) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 0) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 1) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 1) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.id, data.training_title_id, 1) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 1) }}
                                        {{ durationEnd(data.id, data.training_title_id, 1) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 1) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 2) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 2) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.id, data.training_title_id, 2) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 2) }}
                                        {{ durationEnd(data.id, data.training_title_id, 2) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 2) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 3) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 3) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 3) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 3) }}
                                        {{ durationEnd(data.id, data.training_title_id, 3) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 3) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 4) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 4) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 4) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 4) }}
                                        {{ durationEnd(data.id, data.training_title_id, 4) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 4) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 5) > 1 || calculateColspanYear(data.training_title_id, 0) > 0)">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 5) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 5) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 5) }}
                                        {{ durationEnd(data.id, data.training_title_id, 5) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 5) }}
                                      </b-td>
                                      <!-- <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 7) }}
                                      </b-td>
                                      <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 8) }}
                                      </b-td>
                                      <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 9) }}
                                      </b-td>
                                      <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 10) }}
                                      </b-td>
                                      <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 11) }}
                                      </b-td>
                                      <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 0) }}
                                      </b-td>
                                      <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 1) }}
                                      </b-td>
                                      <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 2) }}
                                      </b-td>
                                      <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 3) }}
                                      </b-td>
                                      <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 4) }}
                                      </b-td>
                                      <b-td style="font-size: 12px">
                                        {{ duration(data.training_title_id, 5) }}
                                      </b-td> -->
                                    </b-tr>
                                  </b-tbody>
                                      <!-- <b-tbody>
                                          <b-tr v-for="(data, index) in datas" :key="index" style="font-size: 8px">
                                            <b-td style="font-size: 10px">
                                              {{ $n(index + 1) }}
                                            </b-td>
                                            <b-td style="font-size: 10px">
                                              {{ getTrainingTitle(data.training_title_id) }}
                                            </b-td>
                                            <b-td style="font-size: 10px">
                                              {{ $n(data.no_of_days) }}
                                            </b-td>
                                            <b-td style="font-size: 10px">
                                              {{ $n( getBatch(data.training_title_id)) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 6)">
                                                {{ duration(data.training_title_id, 6) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 7)">
                                                {{ duration(data.training_title_id, 7) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 8)">
                                                {{ duration(data.training_title_id, 8) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 9)">
                                                {{ duration(data.training_title_id, 9) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 10)">
                                                {{ duration(data.training_title_id, 10) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 11)">
                                                {{ duration(data.training_title_id, 11) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 0)">
                                                {{ duration(data.training_title_id, 0) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 1)">
                                                {{ duration(data.training_title_id, 1) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 2)">
                                                {{ duration(data.training_title_id, 2) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 3)">
                                                {{ duration(data.training_title_id, 3) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 4)">
                                                {{ duration(data.training_title_id, 4) }}
                                            </b-td>
                                            <b-td :colspan="calculateColspan(data.training_title_id, 5)">
                                                {{ duration(data.training_title_id, 5) }}
                                            </b-td>
                                          </b-tr>
                                      </b-tbody> -->
                                </b-table-simple>
                              </b-col>
                            </b-row>
                            <b-row class=" mt-5">
                              <b-col sm="8"></b-col>
                              <b-col class="text-center" sm="4" v-if="search.org_id == 12">
                                {{ $i18n.locale==='en' ? directorGenerialData.name : directorGenerialData.name_bn }}<br>
                                {{ $i18n.locale==='en' ? directorGenerialData.designation_name : directorGenerialData.designation_name_bn }}<br>
                                {{ $i18n.locale==='en' ? 'NATA, Gazipur' : 'নাটা, গাজীপুর' }}
                              </b-col>
                              <b-col class="text-center" sm="4" v-if="search.org_id == 4">
                                {{ $i18n.locale==='en' ? directorGenerialData.name : directorGenerialData.name_bn }}<br>
                                {{ $i18n.locale==='en' ? directorGenerialData.designation_name : directorGenerialData.designation_name_bn }}<br>
                                {{ $i18n.locale==='en' ? directorGenerialData.org + ', ' + directorGenerialData.address : directorGenerialData.org_bn + ', ' + directorGenerialData.address_bn }}
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col class="text-center" v-if="$i18n.locale==='bn' && datas.length > 0"><strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer1') }} {{ $t('tpm_report.report_footer2') }}</b-col>
                              <b-col class="text-center" v-if="$i18n.locale==='en' && datas.length > 0">{{ $t('tpm_report.report_footer1') }} <strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer2') }}</b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingCalenderFiscalYearWise, circularList, trainingCalenderPdf, trainingCalenderWord } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  props: ['id'],
  components: {
      ListReportHead
  },
  created () {
    this.search = Object.assign({}, this.search, {
      org_id: this.$store.state.dataFilters.orgId,
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
    }
    this.getCircularList()
    this.searchData()
  },
  data () {
      return {
        saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
        search: {
          org_id: '',
          fiscal_year_id: ''
        },
        searchHeaderData: {
          fiscal_year_en: '',
          fiscal_year_bn: '',
          org_en: '',
          org_bn: ''
        },
        headerExcelDefault: {
          orgName: null,
          orgNameBn: null,
          address: null,
          address_bn: null
        },
        trainingCategoryList: [],
        trainingTitleList: [],
        trainingTypeList: [],
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        datas: [],
        directorGenerialData: {},
        circularList: [],
        allBatchDataList: [],
        batchList: [],
        loading: false,
        loader: false,
        circularLoading: false,
        showData: false,
        myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
      }
  },
  mounted () {
      core.index()
      flatpickr('.fromDate', {})
      this.AveragePriceText = (parseInt(this.search.HighestPrice) + parseInt(this.search.LowestPrice)) / 2
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    monthList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Jan' : 'জানু', text_en: 'Jan', text_bn: 'জানু' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Feb' : 'ফেব্রু', text_en: 'Feb', text_bn: 'ফেব্রু' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Mar' : 'মার্চ', text_en: 'Mar', text_bn: 'মার্চ' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Apr' : 'এপ্রিল', text_en: 'Apr', text_bn: 'এপ্রিল' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'May' : 'মে', text_en: 'May', text_bn: 'মে' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'Jun' : 'জুন', text_en: 'Jun', text_bn: 'জুন' },
        { value: 7, text: this.$i18n.locale === 'en' ? 'Jul' : 'জুলাই', text_en: 'Jul', text_bn: 'জুলাই' },
        { value: 8, text: this.$i18n.locale === 'en' ? 'Aug' : 'আগস্ট', text_en: 'Aug', text_bn: 'আগস্ট' },
        { value: 9, text: this.$i18n.locale === 'en' ? 'Sep' : 'সেপ্টে', text_en: 'Sep', text_bn: 'সেপ্টে' },
        { value: 10, text: this.$i18n.locale === 'en' ? 'Oct' : 'অক্টো', text_en: 'Oct', text_bn: 'অক্টো' },
        { value: 11, text: this.$i18n.locale === 'en' ? 'Nov' : 'নভে', text_en: 'Nov', text_bn: 'নভে' },
        { value: 12, text: this.$i18n.locale === 'en' ? 'Dec' : 'ডিসে', text_en: 'Dec', text_bn: 'ডিসে' }
      ]
    },
    currentFiscalYearId: function () {
      return this.$store.state.TrainingElearning.currentFiscalYearId
    },
    headerValue: function () {
      const headerVal = []
      if (this.$i18n.locale === 'en') {
        headerVal[0] = this.headerExcelDefault.orgName
        headerVal[1] = this.headerExcelDefault.address
        headerVal[2] = this.$t('tpm_report.allowance_report')
      } else {
        headerVal[0] = this.headerExcelDefault.orgNameBn
        headerVal[1] = this.headerExcelDefault.address_bn
        headerVal[2] = this.$t('tpm_report.allowance_report')
      }
      if (this.search.date) {
        headerVal.push(
          this.$t('globalTrans.date') + ' :  ' + this.search.date
        )
      }
      if (this.search.fiscal_year_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('globalTrans.fiscal_year') + ' :  ' + this.search.fiscal_year_bn : this.$t('globalTrans.fiscal_year') + ' :  ' + this.search.fiscal_year
        )
      }
      if (this.search.org_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.organization') + ' :  ' + this.search.org_name_bn : this.$t('elearning_config.organization') + ' :  ' + this.search.org_name
        )
      }
      if (this.search.training_type_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_type') + ' :  ' + this.search.training_type_bn : this.$t('elearning_config.training_type') + ' :  ' + this.search.training_type
        )
      }
      if (this.search.training_category_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_category') + ' :  ' + this.search.training_category_bn : this.$t('elearning_config.training_category') + ' :  ' + this.search.training_category
        )
      }
      if (this.search.training_title_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_title') + ' :  ' + this.search.training_title : this.$t('elearning_config.training_title') + ' :  ' + this.search.training_title
        )
      }
      return headerVal
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    i18 () {
      return this.$i18n.locale
    }
  },
  watch: {
  },
  methods: {
    async pdfExport () {
      this.loader = true
      const obj = {
          role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id
      }
      const serchData = {
        fiscal_year_id: this.search.fiscal_year_id,
        org_id: this.search.org_id,
        training_type_id: this.search.training_type_id,
        training_category_id: this.search.training_category_id,
        training_title_id: this.search.training_title_id,
        name: this.directorGenerialData.name,
        name_bn: this.directorGenerialData.name_bn,
        designation_name: this.directorGenerialData.designation_name,
        designation_name_bn: this.directorGenerialData.designation_name_bn,
        org: this.directorGenerialData.org,
        org_bnn: this.directorGenerialData.org_bn,
        address: this.directorGenerialData.address,
        address_bn: this.directorGenerialData.address_bn
      }
      const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
        const customItem = {
          fiscal_year_en: fiscalYear?.text_en,
          fiscal_year_bn: fiscalYear?.text_bn
        }
      const searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, obj, serchData, searchHeaderData)
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, trainingCalenderPdf, params)
      var blob = new Blob([result], {
          type: 'application/pdf'
      })
      this.loader = false
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
    },
    async wordExport () {
      this.loader = true
      const obj = {
          role: this.$store.state.Auth.activeRoleId,
          auth_org: this.$store.state.Auth.authUser.org_id
      }
      const serchData = {
        fiscal_year_id: this.search.fiscal_year_id,
        org_id: this.search.org_id,
        training_type_id: this.search.training_type_id,
        training_category_id: this.search.training_category_id,
        training_title_id: this.search.training_title_id
      }
      const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
        const customItem = {
          fiscal_year_en: fiscalYear?.text_en,
          fiscal_year_bn: fiscalYear?.text_bn
        }
      const searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
      const params = Object.assign({ request_type: 'word', local: this.$i18n.locale }, obj, serchData, searchHeaderData)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      try {
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, trainingCalenderWord, params)

        // Create a Blob and object URL
        var blob = new Blob([result], {
          type: 'application/msword' // Use 'application/msword' for Word documents
        })
        var url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'training_calender.docx' // Set the desired filename with .docx extension for Word documents
        this.loader = false
        // Trigger the download and cleanup
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        // Handle any errors here
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    countDay (id) {
      const data = this.datas.find(item => item.training_title_id === id)
      const startDate = new Date(data.training_start_date)
      const endDate = new Date(data.training_end_date)
      endDate.setDate(endDate.getDate() + 1)
      const differenceInMs = endDate - startDate
      const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24)
      return differenceInDays
    },
    getTrainingTitle (id) {
      const trainingTitle = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return trainingTitle !== undefined ? trainingTitle.text_bn : ''
      } else {
          return trainingTitle !== undefined ? trainingTitle.text_en : ''
      }
    },
    getBatch (id) {
      const data = this.datas.filter(item => item.training_title_id === id)
      return data.length
    },
    // calculateColspan (id, monthId) {
    //     const month = this.datas.filter(item => {
    //         const date = new Date(item.training_start_date)
    //         return item.training_title_id === id && date.getMonth() === monthId
    //     })

    //     if (month.length === 0) {
    //         return 1
    //     }

    //     const startMonth = new Date(month[0].training_start_date).getMonth()
    //     const endMonth = new Date(month[0].training_end_date).getMonth()

    //     return endMonth - startMonth + 1
    // },
    calculateColspan (id, monthId) {
      const month = this.datas.filter(item => {
        const date = new Date(item.training_start_date)
        const dateEnd = new Date(item.training_end_date)
        return item.training_title_id === id && (date.getMonth() === monthId || dateEnd.getMonth() === monthId)
      })
      if (month.length === 0) {
        return ''
      }
      const startMonth = new Date(month[0].training_start_date).getMonth()
      const endMonth = new Date(month[0].training_end_date).getMonth()
      // return (endMonth - startMonth + 1) * 30 + 'px'
            return ((endMonth - startMonth) + 1)
    },
    calculateColspanYear (id, monthId) {
      const month = this.datas.filter(item => {
        const date = new Date(item.training_start_date)
        const dateEnd = new Date(item.training_end_date)
        return item.training_title_id === id && (date.getMonth() === monthId || dateEnd.getMonth() === monthId)
      })
      if (month.length === 0) {
        return ''
      }
      const startMonth = new Date(month[0].training_start_date).getYear()
      const endMonth = new Date(month[0].training_end_date).getYear()
      // return (endMonth - startMonth + 1) * 30 + 'px'
      return (endMonth - startMonth)
    },
    durationStart (tid, id, monthId) {
        const month = this.datas.filter(item => {
            const date = new Date(item.training_start_date)
            // const dateX = new Date(item.training_end_date)
            // return (item.training_title_id === id && date.getMonth() === monthId) || (item.training_title_id === id && dateX.getMonth() === monthId)
            return (item.id === tid && item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
            return null
        }
        const startDate = Math.min(...month.map(item => new Date(item.training_start_date).getDate()))
        // const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        const startMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_start_date).getMonth() + 1))
        // const endMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_end_date).getMonth() + 1))
        const startMonthData = startMonth ? startMonth.text : null
        // const endMonthData = endMonth ? endMonth.text : null
        return this.$n(startDate) + ' ' + startMonthData
    },
    durationEnd (tid, id, monthId) {
        const month = this.datas.filter(item => {
            const date = new Date(item.training_end_date)
            // const dateX = new Date(item.training_end_date)
            // return (item.training_title_id === id && date.getMonth() === monthId) || (item.training_title_id === id && dateX.getMonth() === monthId)
            return (item.id === tid && item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
            return null
        }
        const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        const endMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_end_date).getMonth() + 1))
        const endMonthData = endMonth ? endMonth.text : null
        return this.$n(endDate) + ' ' + endMonthData
    },
    duration (tid, id, monthId) {
        const month = this.datas.filter(item => {
            const date = new Date(item.training_start_date)
            // const dateX = new Date(item.training_end_date)
            // return (item.training_title_id === id && date.getMonth() === monthId) || (item.training_title_id === id && dateX.getMonth() === monthId)
            return (item.id === tid && item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
            return null
        }
        const startDate = Math.min(...month.map(item => new Date(item.training_start_date).getDate()))
        const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        // const startMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_start_date).getMonth() + 1))
        const endMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_end_date).getMonth() + 1))
        // const startMonthData = startMonth ? startMonth.text : null
        const endMonthData = endMonth ? endMonth.text : null
        return this.$n(startDate) + '-' + this.$n(endDate) + ' ' + endMonthData
    },
//     duration (tid, id, monthId) {
//     // Filter data based on training_title_id and monthId
//     const month = this.datas.filter(item => {
//         const startDate = new Date(item.training_start_date)
//         const endDate = new Date(item.training_end_date)

//         // Check if training_title_id matches and both start and end dates are in the given monthId
//         return (
//             item.id === tid &&
//             item.training_title_id === id &&
//             startDate.getMonth() === monthId &&
//             endDate.getMonth() === monthId
//         )
//     })

//     // Return null if no matching training found
//     if (month.length === 0) {
//         return null
//     }

//     // Retrieve the month name from monthId
//     const monthInfo = this.monthList.find(doc => doc.value === (monthId + 1))
//     const monthName = monthInfo ? monthInfo.text : ''

//     // Initialize an array to store all durations
//     const durations = month.map(item => {
//         const startDate = new Date(item.training_start_date).getDate()
//         const endDate = new Date(item.training_end_date).getDate()

//         // Format each duration as a string and return it
//         return `${this.$n(startDate)} - ${this.$n(endDate)} ${monthName}`
//     })

//     // Join all durations with a comma and space
//     return durations.join(', ')
// },
    duration2 (id, monthId) {
        const month = this.datas.filter(item => {
            const date = new Date(item.training_start_date)
            return (item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
            return null
        }
        const startDate = Math.min(...month.map(item => new Date(item.training_start_date).getDate()))
        const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        return this.$n(startDate) + '-' + this.$n(endDate)
    },
    fiscalYearStart () {
      const data = this.$store.state.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id && item.status === 0)
      const fiscalStart = data.text.split('')
      const fiscalStartYear = fiscalStart[2] + fiscalStart[3]
      return fiscalStartYear
    },
    fiscalYearEnd () {
      const data = this.$store.state.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id && item.status === 0)
      const fiscalEnd = data.text.split('')
      const fiscalEndYear = fiscalEnd[7] + fiscalEnd[8]
      return fiscalEndYear
    },
    getOrgName (id) {
      const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
          return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getOrg (id) {
      const sectorObj = this.$store.state.commonObj.organizationProfileList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.search.fiscal_year_id,
        training_type_id: this.search.training_type_id,
        training_category_id: this.search.training_category_id,
        training_title_id: this.search.training_title_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
        this.allBatchListData = result.batchList
      }
      this.circularLoading = false
    },
    async searchData () {
      this.loading = true
      this.showData = true
      if (this.search.fiscal_year_id) {
        const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
        const customItem = {
          fiscal_year_en: fiscalYear?.text_en,
          fiscal_year_bn: fiscalYear?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
      }

      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingCalenderFiscalYearWise, this.search)
      if (result.success) {
        this.loading = false
        if (result.data) {
          this.datas = this.getCustomDataList(result.data)
          this.directorGenerialData = this.getDirectorDataList(result.directorGenerialData.data)
        }
      } else {
        this.datas = []
        this.directorGenerialData = {}
        this.loading = false
      }
    },
    getDirectorDataList (data) {
      // const listData = data.map(item => {
        const degObj = this.$store.state.commonObj.masterDesignationList.find(doc => doc.value === parseInt(data.designation_id) && doc.status === 0)
        const designationData = {}
        if (typeof degObj !== 'undefined') {
          designationData.designation_name = degObj.text_en
          designationData.designation_name_bn = degObj.text_bn
        } else {
          designationData.designation_name = ''
          designationData.designation_name_bn = ''
        }
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(data.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org = orgObj.abbreviation
          orgData.address = orgObj.address
          orgData.org_bn = orgObj.abbreviation_bn
          orgData.address_bn = orgObj.address_bn
        } else {
          orgData.org = ''
          orgData.org_bn = ''
          orgData.address = ''
          orgData.address_bn = ''
        }
        return Object.assign({}, data, designationData, orgData)
      // })
      // return listData
    },
    getCustomDataList (data) {
      const listData = data.map(item => {
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org = orgObj.text_en
          orgData.org_bn = orgObj.text_bn
        } else {
          orgData.org = ''
          orgData.org_bn = ''
        }

        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
        const fiscalYearData = {}
        if (typeof fiscalYearObj !== 'undefined') {
          fiscalYearData.fiscal_year = fiscalYearObj.text_en
          fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          fiscalYearData.fiscal_year = ''
          fiscalYearData.fiscal_year_bn = ''
        }
        return Object.assign({}, item, orgData, fiscalYearData)
      })
      return listData
    }
  }
}
</script>
